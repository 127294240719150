import React from 'react'
import Navbar from '../components/Navbar'
import Hero from '../components/Hero';
import Slider from '../components/Slider';
import Faqs from '../components/Faqs';
import Foot from '../components/Foot';
import Facts from '../components/Facts';
import CurrentProgrammes from '../components/CurrentProgrammes';
import HowItWorks from '../components/HowItWorks';

const Homepage = () => {
  return (
    <div>
        <Navbar />
        <Hero />
        <Slider />
        <Facts />
        <HowItWorks />
        <CurrentProgrammes />
        <Faqs/>
        <Foot fixed = {false}/>
    </div>
  )
}

export default Homepage