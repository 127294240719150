import React, { useEffect, useState } from 'react';
import programmesData from '../assets/programmes.json'; // Adjust the path to your JSON file
import { Link } from 'react-router-dom';
interface Programme {
  name: string;
  description: string;
  duration: string;
  bestfor: string;
}

interface ProgrammeData {
  [key: string]: Programme;
}

const CurrentProgrammes = () => {
  const [selectedItem, setSelectedItem] = useState<string>('1');
    const [difficulty, setDifficulty] = useState<string>("Beginner");
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [onAllRounder, setOnAllRounder] = useState<boolean>(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const selectDifficulty = (level: string) => {
        setDifficulty(level);
        setIsDropdownOpen(false);
    };

    const programmeNames: ProgrammeData = programmesData.programmes;
    const selectedProgramme = programmeNames[selectedItem];

    const courseIncludes: string[] = [
        "Self-Paced",
        "Full Setup Guide",
        "Recorded Lectures",
        "Certified Teachers",
        "Online Resources",
        "24/7 Discussion Server",
        "And More!",
    ];
    useEffect(() => {
      if (selectedProgramme.name === "All Rounder") {
          setOnAllRounder(true);
      } else {
          setOnAllRounder(false);
      }
  }, [selectedProgramme]);
  return (
    <div className='max-w-full w-7/8 2xl:w-5/6 flex flex-row flex-wrap mx-auto justify-center items-center 2xl:py-16 py-4 md:py-10 gap-5 2xl:gap-24 border-b-2'>
                    {/* Course Selection */}
                    <div className="flex flex-col md:px-10 xl:px-0">
                        <h1 className="mb-10 font-headingfont text-2xl md:text-3xl 2xl:text-5xl font-bold">All Programmes</h1>
                        <div className="">
                            <ul className='md:flex md:gap-8 lg:block'>
                                {Object.keys(programmeNames).map((programId) => (
                                    <li key={programId} onClick={() => setSelectedItem(programId)} className={`programmes-items cursor-pointer 2xl:text-4xl md:text-2xl text-2xl py-0 2xl:py-2 ${selectedItem === programId ? 'text-black underline' : 'text-gray-400'}`}>
                                        {programmeNames[programId].name}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    {/* Main Rectangle */}
                    <div className="bg-slate-900 max-w-screen lg:w-2/3 py-10 md:p-10 flex flex-col lg:flex-row gap-10 xl:gap-20">
                        {/* Left */}
                        <div className="w-screen lg:w-2/3 lg:px-0 text-white">
                            <h3 className='font-headingfont px-[5%] lg:px-0 text-3xl font-bold md:text-5xl lg:text-3xl 2xl:text-5xl'>{selectedProgramme.name}</h3>
                            <div className="">
                            <p className='py-6 px-[5%] lg:px-0 text-md md:text-xl lg:text-sm 2xl:text-xl' dangerouslySetInnerHTML={{ __html: selectedProgramme.description }}></p>
                                <p className='pb-6 px-[5%] lg:px-0 text-md md:text-xl lg:text-sm 2xl:text-xl'><span className='font-bold underline'>Best For:</span> {selectedProgramme.bestfor}</p>
                            </div>
                            <button onClick={toggleDropdown} className='bg-main-color mx-[5%] lg:mx-0 px-4 py-2 lg:px-12 2xl:py-3 text-xl lg:text-sm 2xl:text-xl flex flex-row md:w-80 items-center justify-center'>
                            {!onAllRounder && (
                            <div className="flex flex-row">
                                <span>Enrol: <span className='font-bold'>{difficulty}</span></span>
                                <svg className='w-8 lg:w-4 2xl:w-8 h-auto' stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <g>
                                        <path fill="none" d="M0 0h24v24H0z"></path>
                                        <path d="M12 15l-4.243-4.243 1.415-1.414L12 12.172l2.828-2.829 1.415 1.414z"></path>
                                    </g>
                                </svg>
                                </div>
                            )}
                            {onAllRounder && (
                                <a href="https://buy.stripe.com/cN29EmahC5ImcE028b"><div className="w-50 2xl:w-60 px-10 lg:px-0">
                                    
                                    <span className='font-bold'>Enrol<br></br></span>
                                    <span>Holidays<br></br></span>
                                    <span>July 8-August 19<br></br></span>
                                    {/* <span>6 Weeks</span> */}
                                    
                                </div></a>
                            )}
                            </button>
                            {isDropdownOpen && !onAllRounder && (
                                <div className="absolute bg-white text-black rounded shadow-lg w-full md:w-80 font-bodyfont border border-1 border-black ">
                                    <div onClick={() => selectDifficulty("Beginner")} className="cursor-pointer p-2 hover:bg-gray-200 border-b border-black px-10 lg-px-0">Beginner</div>
                                    <div onClick={() => selectDifficulty("Intermediate")} className="cursor-pointer p-2 hover:bg-gray-200 px-10 lg-px-0">Intermediate</div>
                                </div>
                            ) }
                            {!onAllRounder && difficulty==="Beginner" && (
                                <div className="flex flex-col lg:flex-row py-4 lg:py-6 gap-4 lg:gap-6 lg:text-sm 2xl:text-md">
                                    {/* <a href="https://buy.stripe.com/14kg2K0H2gn07jGbII">
                                    <button className="bg-main-color px-4 py-3 w-[90%] lg:w-40 2xl:w-60 mx-[5%] lg:mx-0 hover:bg-black">
                                        <span><br></br>June 10-July 7</span>
                                    </button></a> */}
                                    <a href="https://buy.stripe.com/28o17QahC3Ae9rOcMN"><button className="bg-main-color px-4 py-3 w-[90%] lg:w-40 2xl:w-60  mx-[5%] lg:mx-0 hover:bg-black">
                                        <span> Holidays <br></br>July 8-July 21</span>
                                        {/* <p>4 Lessons</p> */}
                                    </button></a>
                                </div>
                            )}
                            {!onAllRounder && difficulty==="Intermediate" && (
                                <div className="flex flex-col lg:flex-row py-4 lg:py-6 gap-4 lg:gap-8 lg:text-sm 2xl:text-md">
                                    <a href="https://buy.stripe.com/14keYG0H2c6K6fCbIK"><button className="bg-main-color px-4 py-3 w-[90%] lg:w-40 2xl:w-60  mx-[5%] lg:mx-0 hover:bg-black">
                                        <span> Holidays <br></br>July 8-July 21</span>
                                        {/* <p>4 Lessons</p> */}
                                    </button></a>
                                </div>
                            )}
                        </div>

                        {/* Middle */}
                        <div className='hidden lg:block min-h-full bg-white w-1'></div>
                        <div className='lg:hidden min-w-full bg-white h-[2px]'></div>
                        {/* Right */}
                        <div className="px-4 lg:px-0 md:text-center lg:text-left lg:w-1/3 text-white">
                            <h3 className='font-headingfont text-3xl font-bold md:text-5xl lg:text-3xl 2xl:text-5xl'>Features:</h3>
                            <div className="py-6">
                                <ul>
                                    {courseIncludes.map((include, index) => (
                                        <li key={index} className='text-xl md:text-xl py-1 lg:text-sm 2xl:text-lg xl:py-0'>
                                            • {include}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <p className='py-2 lg:px-0 text-xl lg:text-sm 2xl:text-xl'><span className='font-bold underline'>Duration:</span> {selectedProgramme.duration}</p>
                            <Link to="/contact">
                                <button className='bg-main-color font-bold mt-4 py-2 text-xl lg:text-sm 2xl:text-xl flex flex-row md:mx-auto lg:mt-8 px-12 lg:py-3 lg:mx-0 items-center justify-center'>
                                    Enquire
                                </button>
                            </Link>
                        </div>
                    </div>
                    <p className='text-center max-w-[80%]'>Note that the time duration (# Weeks) is only an indicator of when lessons will be held live. Even if lessons are not attended live they can be completed at any time, including after the duration has passed.</p>
                </div>
  )
}

export default CurrentProgrammes