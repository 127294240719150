import React from 'react'

const Facts = () => {
  return (
    <div className='max-w-full py-12 border-b-2'>
        <div className="w-5/6 md:w-2/3 flex flex-col items-center justify-center mx-auto">
            <h2 className='font-headingfont text-xl md:text-2xl xl:text-3xl text-center'>The world is moving fast. <br /> Learning technology is the <span className='text-main-color'>only</span> way to get ahead.</h2>
            <div className="flex flex-row gap-10 mt-8">
                <div className="flex flex-col items-center justify-center w-full sm:w-auto">
                    <h1 className='text-main-color text-3xl md:text-5xl lg:text-6xl 2xl:text-7xl font-[montserrat] font-medium'>861k</h1>
                    <h3 className='text-md sm:text-xl text-center md:text-2xl mt-2 md:mt-4 font-bodyfont'>Australians work in tech</h3>
                </div>
                <div className="w-[2px] bg-gray-400 h-auto"></div>
                <div className="flex flex-col items-center justify-center w-full sm:w-auto">
                    <h1 className='text-main-color text-3xl md:text-5xl lg:text-6xl 2xl:text font-[montserrat] font-medium'>1.2m</h1>
                    <h3 className='text-md sm:text-xl text-center md:text-2xl mt-2 md:mt-4 font-bodyfont'>AU tech jobs by 2030</h3>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Facts