import React from 'react';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import './App.css';
// import Homepage from './pages/Homepage';
// import Down from './pages/Down';
import About from './pages/About';
import Programmes from './pages/Programmes';
import Contact from './pages/Contact';
import Submitted from './pages/Submitted';
import Homepage from './pages/Homepage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage/>} />
        <Route path="/programmes" element={<Programmes/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/submitted" element={<Submitted/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
