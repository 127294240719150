import React from 'react'
import heroImage from '../assets/hero.jpeg'

const Hero = () => {
  return (
    <div className="border2 w-full ">
    <div className='w-5/6 xl:w-4/6 md:h-[80vh] mx-auto  container flex items-center justify-between flex-col sm:flex-row gap-10 py-20'>
        <div className="w-full md:w-5/6 lg:w-1/2">
            <h1 className='font-herofont text-5xl lg:text-6xl 2xl:text-7xl font-bold mb-8'>
                Give your child a <span className='text-main-color'>headstart</span> in the tech world
            </h1>
            <p className='font-bodyfont text-lg mb-8'>We give kids short, enjoyable lessons about technology to get them ready for the future; design, coding, game design, we have it all!</p>
            <button className='font-buttonfont buttonstyle text-white bg-main-color'><a href='/programmes'>See Programmes</a></button>
        </div>
        <div className="w-full md:w-2/3 lg:w-1/2 hidden md:block">
            <img className="rounded-2xl 2xl:max-w-[500px]" src={heroImage} alt='hero im age'></img>
        </div>
    </div>
    </div>
  )
}

export default Hero