import React from 'react';

const Slider = () => {
  return (
    <div className="slider-container">
      <div className="slider-content">
        <div className="slidertext">Limited Spots Available</div>
        <div className="slidertext">Self-Paced</div>
        <div className="slidertext">Online Learning</div>
        <div className="slidertext">New Content</div>
        <div className="slidertext">Online Discussion Board</div>
        {/* Duplicate the list items to make the loop smooth */}
        <div className="slidertext">Limited Spots Available</div>
        <div className="slidertext">Self-Paced</div>
        <div className="slidertext">Online Learning</div>
        <div className="slidertext">New Content</div>
        <div className="slidertext">Online Discussion Board</div>
  
      </div>
    </div>
  );
};

export default Slider;